* {
  box-sizing: border-box;
  margin:0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

body::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(126, 126, 126);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(44, 43, 43);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}